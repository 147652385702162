html {
  background-color: #F6F6F6;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svg1 {
  filter: invert(46%) sepia(16%) saturate(1852%) hue-rotate(330deg) brightness(91%) contrast(82%);
}

.svg2 {
  filter: invert(49%) sepia(16%) saturate(1708%) hue-rotate(178deg) brightness(92%) contrast(94%);
}

.svgIcon {
  filter: invert(69%) sepia(1%) saturate(0%) hue-rotate(180deg) brightness(200%) contrast(92%);
}

.svgIcon2 {
  filter: invert(69%) sepia(1%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(92%);
}

/* Carousel Front and Back Arrows */
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  opacity: 1;
}

.carousel .control-next.control-arrow:before {
  content: '';
  border: solid #FFFFFF;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  content: '';
  border: solid #FFFFFF;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 10px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background-color: #ffffff;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 20px;
  cursor: pointer !important;
}
::-webkit-scrollbar-track {
  background-color: #3a3a3a;
  border-radius: 15px;
  cursor: pointer !important;
}
::-webkit-scrollbar-thumb {
  background-color: #979797;
  border-radius: 15px;
  cursor: pointer !important;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
}
